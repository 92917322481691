import { z, type util } from "zod"

import { zodFallback } from "~/lib/zod/utils/zod-fallback"

export function sbSingleOption<V extends string, T extends Readonly<[V, ...V[]]>>(
  values: T,
  defaultValue: util.noUndefined<T[number]>
) {
  return z.enum(values).or(zodFallback(defaultValue))
}
