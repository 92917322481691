import type { SbRichtext } from "~/lib/storyblok/schemas/default/richtext"

export function stringIsNotEmpty(str: string | number | string[] | boolean | null | undefined) {
  return str !== "" && str !== null && str !== undefined && typeof str === "string"
}

export function isRTFilled(richText?: SbRichtext | undefined | null) {
  if (!richText) {
    return false
  }

  return (
    richText?.content?.filter((item) => {
      if (item.type === "blok") {
        return Boolean(item.attrs)
      }

      return Boolean(item.content)
    })?.length > 0
  )
}
