import clsx from "clsx"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import type { SbLinkLabel } from "~/lib/storyblok/schemas/library/components/link_label"
import InlineCta, { type InlineCtaProps } from "~/components/ui/InlineCta"
import { capitalize } from "~/utils/capitalize"

import * as css from "./styles.css"

export type TLabelWithInlineCta = {
  label: Nullish<string>
  cta: SbLinkLabel
  ctaProps?: InlineCtaProps
}

function LabelWithInlineCta({ label, cta, ctaProps, className }: PropsWithClassName<TLabelWithInlineCta>) {
  return (
    <div className={clsx(css.LabelWithInlineCta, className)}>
      {label && <span className={css.label}>{capitalize(label)}</span>}
      <InlineCta className={css.cta} {...cta} {...ctaProps} />
    </div>
  )
}

export { LabelWithInlineCta }
