import { z } from "zod"

import type { TTable } from "~/components/ui/Table"

const sbCellSchema = z.object({
  value: z.string(),
})
type RowFromSchema = z.infer<typeof sbRowSchema>

const sbRowSchema = z.object({
  body: z.array(sbCellSchema),
})

const sbBodySchema = z.array(sbRowSchema)
type BodyFromSchema = z.infer<typeof sbBodySchema>

const sbHeadSchema = z.array(sbCellSchema)

export function sbTable() {
  return z
    .object({
      tbody: sbBodySchema,
      thead: sbHeadSchema,
    })
    .transform((data): TTable => {
      return {
        tbody: data?.tbody ? serializeTBody(data.tbody) : undefined,
        thead: data?.thead ? serializeRow({ body: data.thead }) : undefined,
      }
    })
}

function serializeRow(row: RowFromSchema) {
  return { values: row?.body?.map((cell) => cell?.value?.trim()) }
}

function serializeTBody(body: BodyFromSchema) {
  return body.reduce((acc, row) => {
    row.body.forEach((cell, i) => {
      if (!acc[i]) acc[i] = { values: [] }
      acc[i]?.values.push(cell?.value?.trim())
    })
    return acc
  }, [] as { values: string[] }[])
}
