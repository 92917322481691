import { z } from "zod"

export function sbBlocksSchema<Schema extends Parameters<typeof z.union>[0][number]>(
  schemas: [Schema]
): ReturnType<typeof simple<Schema>>
export function sbBlocksSchema<Schemas extends Parameters<typeof z.union>[0]>(
  schemas: Schemas
): ReturnType<typeof multiple<Schemas>>
export function sbBlocksSchema<
  Schemas extends Parameters<typeof z.union>[0],
  Schema extends Parameters<typeof z.union>[0][number]
>(schemas: Schemas | [Schema]): ReturnType<typeof multiple<Schemas>> | ReturnType<typeof simple<Schema>> {
  if (schemas.length === 1) return simple(schemas[0] as Schema)

  return multiple(schemas as Schemas)
}

function multiple<Schemas extends Parameters<typeof z.union>[0]>(schemas: Schemas) {
  return z
    .union(schemas as Schemas)
    .array()
    .default([])
    .transform((val) => val.filter(Boolean))
}

function simple<Schema extends Parameters<typeof z.union>[0][number]>(schema: Schema) {
  return schema
    .array()
    .default([])
    .transform((val) => val.filter(Boolean))
}
