import { clsx } from "clsx"

import type { SbRichtext } from "~/lib/storyblok/schemas/default/richtext"
import RichText from "~/components/ui/RichText"
import type { SliceWithProps } from "~/components/slices/SliceManager"

import * as css from "./styles.css"

export type TSizeGuideParagraph = {
  paragraph: SbRichtext
}
export type SizeGuideTitleProps = SliceWithProps<TSizeGuideParagraph>

function SizeGuideParagraph({ className, paragraph }: SizeGuideTitleProps) {
  return <RichText className={clsx(className, css.SizeGuideParagraph)} render={paragraph} disableStyles />
}

export default SizeGuideParagraph
