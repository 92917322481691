import React from "react"

import serializeJSONLD from "./serialize-json-ld"

interface FaqQuestion {
  name?: string
  acceptedAnswer?: {
    text?: string
  }
}
export interface FaqDataProps {
  mainEntity?: FaqQuestion[]
}

export function FaqData(props: FaqDataProps) {
  return (
    <script
      id="faq-json-ld-data"
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: serializeJSONLD(props),
      }}
    />
  )
}
