import type { FaqDataProps } from "./index"

export default function serializeJSONLD({ mainEntity }: FaqDataProps) {
  return JSON.stringify({
    "@context": "http://schema.org",
    "@type": "FAQPage",
    mainEntity:
      mainEntity?.map((entity) => ({
        "@type": "Question",
        name: entity?.name ?? null,
        acceptedAnswer: {
          "@type": "Answer",
          text: entity?.acceptedAnswer?.text ?? null,
        },
      })) ?? null,
  })
}
