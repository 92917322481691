import { z } from "zod"

import { getProcessedLocale } from "~/lib/i18n/utils/get-processed-locale"
import type { SbSchemaOptions } from "~/lib/storyblok/schemas"
import type { SbLinkFromSchema } from "~/components/ui/Link/_data/schema"
import { defaultLink, type TLink } from "~/components/ui/Link/_data/serializer"
import { hrefResolver } from "~/components/ui/Link/utils/href-resolver"
import isInternalUrl from "~/utils/is-internal-url"

export function sbLinkSchema(options: { locale: string }) {
  return z.union([sbLinkStorySchema(options), sbLinkUrlSchema(options)])
}

export type SbLink = z.infer<ReturnType<typeof sbLinkSchema>>

export function sbLinkStorySchema(options: SbSchemaOptions) {
  return z
    .object({
      fieldtype: z.literal("multilink"),
      linktype: z.literal("story"),
      id: z.string(),
      story: z
        .object({
          slug: z.string(),
          default_full_slug: z.string().catch(""),
          content: z.object({
            component: z.string(),
          }),
          translated_slugs: z
            .array(z.object({ lang: z.string(), path: z.string(), name: z.string().nullable() }))
            .catch([]),
        })
        .optional(),
    })
    .transform<TLink>((data) => {
      if (!data.story) return defaultLink

      const processedLocale = getProcessedLocale(options?.locale)

      return {
        href: hrefResolver(data as SbLinkFromSchema, processedLocale) ?? defaultLink.href,
        rel: defaultLink.rel,
      }
    })
}

const sbLinkUrl = z.object({
  id: z.string(),
  url: z.string(),
  linktype: z.literal("url"),
  fieldtype: z.literal("multilink"),
  target: z.enum(["_blank", "_self"]).optional(),
})

type SbLinkUrl = z.infer<typeof sbLinkUrl>

export function sbLinkUrlSchema(options: SbSchemaOptions) {
  return sbLinkUrl.transform<TLink>((data) => serializeSbLinkUrl(data, options.locale))
}

function serializeSbLinkUrl(link: SbLinkUrl, locale: string) {
  const { status, pathname } = isInternalUrl(link.url)
  const rel = status ? "noopener" : "noopener nofollow"

  const href = status ? `/${locale}${pathname}` : link.url

  return {
    href,
    rel,
    isExternal: !status,
    target: link.target ?? "_self",
  }
}
