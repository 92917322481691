import asText from "~/components/ui/RichText/_data/mock"
import type { RichtextAccordionProps } from "~/components/ui/RichTextAccordion"
import type { FaqDataProps } from "~/components/abstracts/JSONLD/Faq"

export default function serializeFaqJsonLd(items: RichtextAccordionProps["items"]): FaqDataProps {
  return {
    mainEntity: items
      ?.map((item) => {
        const text = asText(item.body)

        if (!text) return null

        return {
          name: item.head,
          acceptedAnswer: {
            text,
          },
        }
      })
      .filter(Boolean),
  }
}
