import { z } from "zod"

import type { SbSchemaOptions } from "~/lib/storyblok/schemas"
import { sbText } from "~/lib/storyblok/schemas/default/text"
import { sbComponent } from "~/lib/storyblok/schemas/helpers"
import { sbComponentLinkLabelSchema } from "~/lib/storyblok/schemas/library/components/link_label"
import type { TLabelWithInlineCta } from "~/components/ui/LabelWithInlineCta"

export function sbLabelWithLinkLabelSchema(options: SbSchemaOptions) {
  return sbComponent("label_with_link_label")
    .extend({
      label: sbText().optional(),
      link: z.array(sbComponentLinkLabelSchema(options)),
    })
    .transform<TLabelWithInlineCta | null>((data) => {
      if (!data.link[0]) return null

      return {
        label: data.label ?? null,
        cta: data.link[0],
      }
    })
}

export type SbComponentLabelWithLinkLabel = z.infer<ReturnType<typeof sbLabelWithLinkLabelSchema>>
