import type { Nullish } from "~/@types/generics"
import type { LinkFromSchema, SbLinkLabelFromSchema } from "~/components/ui/Link/_data/schema"
import { getSbLinkLabel } from "~/components/ui/Link/utils/get-sb-link-label"
import { hrefResolver } from "~/components/ui/Link/utils/href-resolver"
import isInternalUrl from "~/utils/is-internal-url"

export type TLink = {
  href: string
  rel: string
  isExternal?: boolean
  target?: "_blank" | "_self"
  obfuscate?: boolean
}

export type TLinkLabel = TLink & { children: string }

export const defaultLink = { href: "/", rel: "noopener", obfuscate: false }

export default function serializeLink(link: Nullish<LinkFromSchema>, locale: Nullish<string>): TLink {
  if (!link) return defaultLink

  if ("linktype" in link && link.linktype === "url") {
    const { status } = isInternalUrl(link.url)
    const rel = status ? "noopener" : "noopener nofollow"

    return {
      href: link.url,
      isExternal: true,
      target: link.target ?? "_self",
      obfuscate: link?.obfuscate ?? defaultLink.obfuscate,
      rel,
    }
  }

  return {
    href: hrefResolver(link, locale) ?? defaultLink.href,
    rel: defaultLink.rel,
    obfuscate: link?.obfuscate ?? defaultLink.obfuscate,
  }
}

export function serializeLinkLabel(linkLabel: SbLinkLabelFromSchema[number], locale: Nullish<string>) {
  const { link, label } = getSbLinkLabel(linkLabel)

  return { ...serializeLink(link, locale), children: label }
}
