import type { RecipeVariants } from "@vanilla-extract/recipes"
import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import AbstractTable from "~/components/abstracts/Table"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"
import { text } from "~/styles/variants"

import * as css from "./styles.css"

export type TTable = {
  tbody:
    | {
        values: string[]
      }[]
    | undefined
  thead:
    | {
        values: string[]
      }
    | undefined
}
export type TableProps = PropsWithClassName<
  TTable & {
    bodyText?: RecipeVariants<typeof text>
    bodyBgColor?: Sprinkles["bgColor"]
    headText?: RecipeVariants<typeof text>
    headBgColor?: Sprinkles["bgColor"]
  }
>

function Table({
  className,
  headText = { design: "body-neue-12", color: "black", fontWeight: "medium" },
  headBgColor = "grey-alabaster",
  bodyText = { design: "body-neue-12", color: "black" },
  bodyBgColor = "white",
  ...rest
}: TableProps) {
  return (
    <div className={clsx(className)}>
      <AbstractTable
        className={clsx(css.Table)}
        theadClassName={clsx(css.thead, text(headText), sprinkles({ bgColor: headBgColor }))}
        tbodyClassName={clsx(css.tbody, text(bodyText), sprinkles({ bgColor: bodyBgColor }))}
        trowClassName={clsx(css.trow)}
        {...rest}
      />
    </div>
  )
}

export default Table
