import clsx from "clsx"

interface TableRow {
  values?: string[]
}

export interface TableProps {
  className?: string
  hasDifferentLayoutInDesktop?: boolean
  thead?: TableRow
  tbody?: TableRow[]
  combinedTbody?: TableRow[]
  theadClassName?: string
  tbodyClassName?: string
  trowClassName?: string
}

function Table({ className, thead, tbody, theadClassName, trowClassName, tbodyClassName }: TableProps) {
  return (
    <table data-comp="Abstracts/Table" className={clsx(className)}>
      {thead &&
        thead?.values?.map((value, index) => (
          <tr key={`thead_tr_${index}`} className={clsx(trowClassName)}>
            <th className={clsx(theadClassName)} key={`thead_td_${index}`}>
              {value}
            </th>
            {tbody?.[index]?.values?.map((value, columnIndex) => (
              <td key={`tbody_tr_${index}_td_${columnIndex}`} className={clsx(tbodyClassName)}>
                {value}
              </td>
            ))}
          </tr>
        ))}
    </table>
  )
}

export default Table
