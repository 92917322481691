import type { PropsWithChildren, ReactElement } from "react"

import Icon, { type IconProps } from "~/components/abstracts/Icon"

import * as css from "./styles.css"

type PanelHeadProps = PropsWithChildren<{
  title: string
  isActive: boolean
  activeIcon?: ReactElement<IconProps>
  inactiveIcon?: ReactElement<IconProps>
}>

export function AccordionPanelHead({
  title,
  isActive,
  activeIcon = <Icon width={10} name="Minus" />,
  inactiveIcon = <Icon width={10} name="Plus" />,
  children,
}: PanelHeadProps) {
  return (
    <>
      <div data-comp="UI/AccordionPanelHead" className={css.title}>
        {title && title}
        {children}
      </div>
      {isActive ? activeIcon : inactiveIcon}
    </>
  )
}
