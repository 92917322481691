import { z } from "zod"

import type { SbSchemaOptions } from "~/lib/storyblok/schemas"
import { sbAssetImageSchema } from "~/lib/storyblok/schemas/default/assets/image"
import { sbLinkSchema } from "~/lib/storyblok/schemas/default/link"
import { sbComponent } from "~/lib/storyblok/schemas/helpers"
import type { TLinkLabel } from "~/components/ui/Link/_data/serializer"

export function sbComponentLinkLabelSchema(options: SbSchemaOptions) {
  return sbComponent("link_label")
    .extend({
      link: sbLinkSchema(options),
      label: z.string(),
      icon: sbAssetImageSchema(options).optional(),
      obfuscate: z.boolean().optional(),
    })
    .transform<TLinkLabel>((data) => ({
      ...data.link,
      iconFromSb: data.icon ?? null,
      children: data.label,
      obfuscate: data.obfuscate ?? false,
    }))
}

export type SbLinkLabel = z.infer<ReturnType<typeof sbComponentLinkLabelSchema>>
