import type { Nullish } from "~/@types/generics"
import { HOST_NAME } from "~/lib/constants"
import { getAllLocales } from "~/lib/i18n/utils/get-all-locales"

const URL_PATH_REGEX = /(\/[0-9A-Za-z].*\?|$)/g

interface IIsInternalUrl {
  status: boolean
  pathname: string
}

/**
 * It checks if the given value is a URL that points to the same host as the current page
 * @param {string} value - The URL to check
 * @returns A boolean value.
 */
export default function isInternalUrl(value: Nullish<string>): IIsInternalUrl {
  if (!value) return { status: false, pathname: "" }

  try {
    const url = new URL(value)
    return { status: url.hostname === HOST_NAME, pathname: sanitizePathname(url.pathname) }
  } catch {
    if (value.startsWith("/")) {
      return { status: true, pathname: sanitizePathname(value) }
    }

    return { status: URL_PATH_REGEX.test(value), pathname: sanitizePathname(value) }
  }
}

function sanitizePathname(pathname: string) {
  const locales = getAllLocales()
  const index = pathname.startsWith("/") ? 1 : 0
  const splittedPathname = pathname.split("/")

  const withLocale = locales.includes(splittedPathname[index] as (typeof locales)[number])

  if (withLocale) {
    const trimPathname = splittedPathname.splice(index + 1, splittedPathname.length)

    return ["", ...trimPathname].join("/")
  }

  return pathname
}
