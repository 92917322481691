import { useContext } from "react"

import { I18nContext } from "~/providers/I18nProvider/ClientProvider"

export function useTranslate() {
  const context = useContext(I18nContext)

  if (!context) {
    throw new Error("useTranslate must be used within a I18nProvider")
  }

  return context
}
