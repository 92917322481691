import type { Nullish } from "~/@types/generics"
import type { SbLinkFromSchema } from "~/components/ui/Link/_data/schema"
import serializeLink from "~/components/ui/Link/_data/serializer"
import isInternalUrl from "~/utils/is-internal-url"

type RichTextLinkUrl = {
  linktype: "url"
  href: string
  target?: "_blank" | "_self"
}

type RichTextLinkStory = SbLinkFromSchema

export type RichTextLink = RichTextLinkUrl | RichTextLinkStory

export function serializeRichTextLink(link: RichTextLink, locale: Nullish<string>) {
  switch (link.linktype) {
    case "story":
      return serializeLink(link, locale)

    case "url": {
      if (!link.href) return null
      const { status, pathname } = isInternalUrl(link.href)
      const rel = status ? "noopener" : "noopener nofollow"

      const href = status ? `/${locale}${pathname}` : link.href

      return {
        href,
        rel,
        isExternal: !status,
        target: link.target ?? "_self",
      }
    }
  }
}
