import type { z } from "zod"

import { sbTable } from "~/lib/storyblok/schemas/default/sbTable"
import { sbSingleOption } from "~/lib/storyblok/schemas/default/single-option"
import { sbComponent, sbSlice } from "~/lib/storyblok/schemas/helpers"
import type { TSizeGuideTable } from "~/components/slices/SizeGuideSlice/SizeGuideTable"

export function sbSliceSizeGuideTableSchema() {
  return sbSlice(sbSizeGuideTableSchema(), (data): TSizeGuideTable => {
    return {
      table: {
        ...data.table,
        headBgColor: data.head_background_color,
        bodyBgColor: data.cell_background_color,
      },
    }
  })
}

export function sbSizeGuideTableSchema() {
  return sbComponent("size_guide_table").extend({
    table: sbTable(),
    head_background_color: sbSingleOption(["white", "grey-alabaster"], "grey-alabaster"),
    cell_background_color: sbSingleOption(["white", "grey-alabaster"], "grey-alabaster"),
  })
}

export type SbSizeGuideTable = z.infer<ReturnType<typeof sbSliceSizeGuideTableSchema>>
