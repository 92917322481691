import { clsx } from "clsx"

import type { SbRichtext } from "~/lib/storyblok/schemas/default/richtext"
import RichText from "~/components/ui/RichText"
import type { SliceWithProps } from "~/components/slices/SliceManager"

import * as css from "./styles.css"

export type TSizeGuideTitle = {
  title: SbRichtext
  withMarginOnMobile?: boolean
}
export type SizeGuideTitleProps = SliceWithProps<TSizeGuideTitle>

function SizeGuideTitle({ className, title, withMarginOnMobile = true }: SizeGuideTitleProps) {
  return (
    <RichText
      className={clsx(className, css.SizeGuideTitle, withMarginOnMobile ? css.mobilePadding : null)}
      render={title}
      disableStyles
    />
  )
}

export default SizeGuideTitle
