export const COOKIE_KEYS = {
  NEXT_LOCALE: "NEXT_LOCALE",
  MARKET_COUNTRY_CODE: "MARKET_COUNTRY_CODE",
  MARKET_LANGUAGE_CODE: "MARKET_LANGUAGE_CODE",
  MARKET_HAS_INTERACTED: "MARKET_HAS_INTERACTED",
  NOSTO_SESSION_ID: "NOSTO_SESSION_ID",
  SEARCHED_TERMS: "SEARCHED_TERMS",
  newsletterPopIn: (locale: string) => `newsletter-pop-in_${locale}`,
  newsletterPopInAttempts: (locale: string) => `newsletter-pop-in_attempts_${locale}`,
  IS_DRAFT_MODE: "isDraftMode",
  RELEASE_ID: "RELEASE_ID",
}
