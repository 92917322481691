"use client"

import clsx from "clsx"

import { Accordion, AccordionPanel } from "@unlikelystudio/react-abstract-components"

import type { PropsWithClassName } from "~/@types/generics"
import type { SbRichtext } from "~/lib/storyblok/schemas/default/richtext"
import { AccordionPanelHead } from "~/components/ui/AccordionPanelHead"
import RichText from "~/components/ui/RichText"
import serializeFaqJsonLd from "~/components/ui/RichText/_data/serializer-json-ld"
import Icon from "~/components/abstracts/Icon"
import { FaqData } from "~/components/abstracts/JSONLD/Faq"
import SizeGuideParagraph from "~/components/slices/SizeGuideSlice/SizeGuideParagraph"
import SizeGuideTable, { type TSizeGuideTable } from "~/components/slices/SizeGuideSlice/SizeGuideTable"
import SizeGuideTitle from "~/components/slices/SizeGuideSlice/SizeGuideTitle"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import * as css from "./styles.css"

export type TableItemProps = { tableTitle: SbRichtext; tableParagraph: SbRichtext; table?: TSizeGuideTable }

export type TableProps = {
  tableItems: TableItemProps[]
}

export type AccordionItemProps = { head: string; body: SbRichtext }
export type RichtextAccordionProps = PropsWithClassName<{ items: Omit<PanelProps, "index">[] }>
export type PanelProps = AccordionItemProps & { index: number } & TableProps

export function TableItems({ tableItems }: TableProps) {
  return tableItems?.map((tableItem, index) => (
    <div key={`accordion-table-${index}`} className={css.table.container}>
      {tableItem?.tableTitle ? (
        <SizeGuideTitle withMarginOnMobile={false} className={css.table.title} title={tableItem?.tableTitle} />
      ) : null}
      {tableItem?.table?.table ? <SizeGuideTable table={tableItem?.table.table} /> : null}
      {tableItem?.tableParagraph ? (
        <SizeGuideParagraph className={css.table.paragraph} paragraph={tableItem?.tableParagraph} />
      ) : null}
    </div>
  ))
}

export function Panel({ index, head, body, tableItems }: PanelProps) {
  const t = useTranslate()

  return (
    body &&
    head && (
      <AccordionPanel
        className={clsx(css.panel)}
        headClassName={clsx(css.head)}
        ariaLabels={{ close: t("close"), open: t("open") }}
        activeClassName={css.contentState({ active: true })}
        index={index}
        head={(isActive: boolean) => (
          <AccordionPanelHead
            title={head}
            isActive={isActive}
            activeIcon={<Icon name="Minus" width={10} />}
            inactiveIcon={<Icon name="ChevronVertical" width={10} />}
          />
        )}
        body={
          <>
            <RichText className={clsx(css.content)} render={body} />
            <TableItems tableItems={tableItems} />
          </>
        }
      />
    )
  )
}

function RichtextAccordion({ items, className }: RichtextAccordionProps) {
  const faqJsonLd = serializeFaqJsonLd(items)
  return (
    <>
      <Accordion className={clsx(css.RichtextAccordions, className)} autoHide={true} index={0}>
        {items.map((item, index) => (
          <Panel key={`panel_${index}`} index={index} {...item} />
        ))}
      </Accordion>
      {faqJsonLd?.mainEntity && faqJsonLd?.mainEntity?.length > 0 && <FaqData {...faqJsonLd} />}
    </>
  )
}

export { RichtextAccordion }
