import { sbBlocksSchema } from "~/lib/storyblok/schemas/default/blocks"
import { sbRichtextSchema } from "~/lib/storyblok/schemas/default/richtext"
import { sbComponent } from "~/lib/storyblok/schemas/helpers"
import { sbSizeGuideTableSchema } from "~/lib/storyblok/schemas/library/size-guide-slices/size_guide_table"
import type { TableItemProps } from "~/components/ui/RichTextAccordion"

export function sbComponentTableItem() {
  return sbComponent("table_item")
    .extend({
      table_title: sbRichtextSchema(),
      table_paragraph: sbRichtextSchema(),
      table: sbBlocksSchema([sbSizeGuideTableSchema()]),
    })
    .omit({ component: true })
    .transform((data) => {
      return {
        tableTitle: data.table_title,
        tableParagraph: data.table_paragraph,
        table: data.table?.[0],
      } satisfies TableItemProps
    })
}
