import { clsx } from "clsx"

import Table, { type TableProps } from "~/components/ui/Table"
import type { SliceWithProps } from "~/components/slices/SliceManager"

import * as css from "./styles.css"

export type TSizeGuideTable = {
  table: TableProps
}
export type SizeGuideTableProps = SliceWithProps<TSizeGuideTable>

function SizeGuideTable({ className, table }: SizeGuideTableProps) {
  return <Table className={clsx(className, css.SizeGuideTable)} {...table} />
}

export default SizeGuideTable
