import type { StoryblokRichtextContent } from "storyblok-rich-text-react-renderer"
import { z } from "zod"

const sbRichtextContentTypeSchema = z.union([
  z.literal("heading"),
  z.literal("code_block"),
  z.literal("paragraph"),
  z.literal("blockquote"),
  z.literal("ordered_list"),
  z.literal("bullet_list"),
  z.literal("list_item"),
  z.literal("horizontal_rule"),
  z.literal("hard_break"),
  z.literal("image"),
  z.literal("emoji"),
  z.literal("blok"),
  z.literal("text"),
])

const sbRichtextContentSchema: z.ZodSchema<StoryblokRichtextContent> = z.lazy(() =>
  z.object({
    type: sbRichtextContentTypeSchema,
    attrs: z.any(),
    marks: z.any(),
    text: z.string().optional(),
    content: z.array(sbRichtextContentSchema).optional(),
  })
)

export function sbRichtextSchema() {
  return z.object({
    type: z.literal("doc"),
    content: z.array(sbRichtextContentSchema),
  })
}

export type SbRichtext = z.infer<ReturnType<typeof sbRichtextSchema>>
