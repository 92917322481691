import type { DetailedHTMLProps, ElementType, HTMLAttributes } from "react"
import { render, type RenderOptions as StoryblokRenderOptions } from "storyblok-rich-text-react-renderer"

import type { ExplicitAny } from "~/@types/generics"
import type { SbRichtext } from "~/lib/storyblok/schemas/default/richtext"
import { isRTFilled } from "~/lib/storyblok/utils/check-empty-string"

interface RenderOptions extends StoryblokRenderOptions {
  blokResolvers?: {
    [key: string]: (props: ExplicitAny) => JSX.Element | null
  }
}
export interface RichTextProps extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  as?: ElementType
  render: SbRichtext | undefined | null
  options?: RenderOptions
}
/**
 * Richtext component
 * @param props
 * @returns Component
 */
function RichText({ ref, className, as: Tag = "div", style, render: renderText, options }: RichTextProps) {
  return isRTFilled(renderText) ? (
    <Tag data-comp="Abstracts/RichText" ref={ref} className={className} style={style}>
      {render(renderText, options)}
    </Tag>
  ) : null
}

export default RichText
